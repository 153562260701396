// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App_shadowed__BC3oN {\n  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4);\n}\n\nbody {\n  font-family: \"Roboto\", Helvetica, sans-serif;\n  font-size: 1.5vh;\n  width: 100%;\n  height: 100%;\n  color: #1f1f1f;\n  background-color: #f8f8f8;\n  box-sizing: border-box;\n}\n\nli {\n  color: #1f1f1f;\n}\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\na,\na:link,\na:hover,\na:visited,\na:active {\n  text-decoration: none;\n}\n\nimg {\n  max-width: 100%;\n}\n\nul {\n  list-style-type: none;\n}", "",{"version":3,"sources":["webpack://./src/variables.modules.scss","webpack://./src/App.module.scss"],"names":[],"mappings":"AAWA;EACE,4CAAA;ACTF;;AACA;EACI,4CAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;EACA,cDNG;ECOH,yBDRI;ECSJ,sBAAA;AAEJ;;AACA;EACI,cDZG;ACcP;;AACA;EACI,SAAA;EACA,UAAA;EACA,sBAAA;AAEJ;;AACA;;;;;EAKI,qBAAA;AAEJ;;AACA;EACI,eAAA;AAEJ;;AACA;EACI,qBAAA;AAEJ","sourcesContent":["$purple: #8D53FF;\n$pink: #CA6BE6;\n$white: #f8f8f8;\n$dark: #1f1f1f;\n$red: #FF6057;\n$yellow: #FFBD2E;\n$green: #27C93F;\n$lightgray: #c9c9c9;\n\n$transition: all 250ms ease;\n\n.shadowed {\n  box-shadow: 0 .5rem 1rem rgba(0,0,0,0.4);\n}","@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');\n@import \"./variables.modules\";\n\n\nbody {\n    font-family: 'Roboto', Helvetica, sans-serif;\n    font-size: 1.5vh;\n    width: 100%;\n    height: 100%;\n    color: $dark;\n    background-color: $white;\n    box-sizing: border-box;\n}\n\nli {\n    color: $dark;\n}\n\n* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n\na,\na:link,\na:hover,\na:visited,\na:active {\n    text-decoration: none;\n}\n\nimg {\n    max-width: 100%;\n}\n\nul {\n    list-style-type: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shadowed": "App_shadowed__BC3oN"
};
export default ___CSS_LOADER_EXPORT___;
