// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".About_shadowed__hsjml {\n  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4);\n}\n\n.About_pink__PEb4I {\n  color: #CA6BE6;\n}\n\n.About_green__Mih4Z {\n  color: #27C93F;\n}\n\n.About_skills__RvndN {\n  columns: 1;\n}\n@media only screen and (min-width: 940px) {\n  .About_skills__RvndN {\n    columns: 2;\n  }\n}\n.About_skills__RvndN li {\n  margin: 0;\n  line-height: 1.75;\n}", "",{"version":3,"sources":["webpack://./src/variables.modules.scss","webpack://./src/components/about/About.module.scss"],"names":[],"mappings":"AAWA;EACE,4CAAA;ACVF;;AAAA;EACE,cDFK;ACKP;;AAAA;EACE,cDDM;ACIR;;AAAA;EACE,UAAA;AAGF;AAFE;EAFF;IAGI,UAAA;EAKF;AACF;AAHE;EACE,SAAA;EACA,iBAAA;AAKJ","sourcesContent":["$purple: #8D53FF;\n$pink: #CA6BE6;\n$white: #f8f8f8;\n$dark: #1f1f1f;\n$red: #FF6057;\n$yellow: #FFBD2E;\n$green: #27C93F;\n$lightgray: #c9c9c9;\n\n$transition: all 250ms ease;\n\n.shadowed {\n  box-shadow: 0 .5rem 1rem rgba(0,0,0,0.4);\n}","@import \"../../variables.modules\";\n\n.pink {\n  color: $pink;\n}\n\n.green {\n  color: $green;\n}\n\n.skills {\n  columns: 1;\n  @media only screen and (min-width: 940px) {\n    columns: 2;\n  }\n\n  li {\n    margin: 0;\n    line-height: 1.75;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shadowed": "About_shadowed__hsjml",
	"pink": "About_pink__PEb4I",
	"green": "About_green__Mih4Z",
	"skills": "About_skills__RvndN"
};
export default ___CSS_LOADER_EXPORT___;
